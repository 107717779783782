@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #324755;
  background-color: #ffff;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #324755;
  font-size: 1em;
  font-weight: 800;
  text-decoration: underline;
  text-underline-offset: 3px;
}

input,
select {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #324755;
  color: #1a3058;
  font-size: 1em;
  border-radius: 10px !important;
  padding: 5px 15px !important;
}

input {
  box-sizing: border-box;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

.link-button {
  color: #263f6a;
  font-weight: 700;
  padding: 0px;
  background: none;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.circular-checkbox {
  background-color: #ffff;
  border: 2px solid #263f6a;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 30px;
  margin-right: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
    background-color: #263f6a;
  }
}

.field-style {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 24px 0px;
}

.formik-error {
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  color: rgb(239 68 68);
}

textarea {
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #324755;
  color: #1a3058;
  font-size: 1em;
  border-radius: 10px;
  padding: 5px 15px !important;
  min-height: 70px;
  max-height: 300px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  border: 1px solid #1591bf;
  box-shadow: 0 0 2px 2px rgb(93, 196, 255);
}

textarea:focus {
  border-radius: 20px 20px 4px 20px;
}

h1,
h2,
h3,
h4,
p {
  color: #324755;
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

p.error {
  font-size: 1em;
  margin: 0px;
  margin-top: 10px;
  color: #d9143a;
}

b {
  color: #263f6a;
  font-size: 18px;
}

li {
  list-style-type: disc;
  margin: 8px 0px;
  margin-left: 10px;
}

ol li {
  list-style-type: decimal;
  margin-left: 20px;
}

th,
td {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: top;
}

.Toastify__toast {
  padding: 10px 10px 10px 15px !important;
  border-radius: 4px !important;
}

.regular-checkbox {
  margin-right: 10px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.swiper-slide img {
  max-width: 800px;
  max-height: calc(100vh - 450px);

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
}

.wordwrap {
  /* wrap long text and urls */
  white-space: pre; /* CSS 2.0 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3.0 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: -moz-pre-wrap; /* Mozilla */
  word-wrap: break-word; /* IE 5+ */
}
